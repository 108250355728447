import "util/wdyr";
import { ChakraProvider } from "@chakra-ui/react";
import { config, dom } from "@fortawesome/fontawesome-svg-core";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import React from "react";
import { Provider, useStore } from "react-redux";
import type { Store } from "redux";
import { PersistGate } from "redux-persist/integration/react";

import MusicPlayerContainer from "components/Player/MusicPlayer/Container";
import Spinner from "components/Spinner";
import Fonts from "components/UI/Fonts";
import { wrapper } from "store/store";
import type { Page } from "types/page";

import theme from "../chakraTheme";

import "./styles.css";
import "react-datepicker/dist/react-datepicker.css";

// Fix for font awesome jumbo icons - see head below.
// https://github.com/FortAwesome/react-fontawesome/issues/284#issuecomment-691707228
config.autoAddCss = false;

type Props = AppProps & { Component: Page };

declare global {
  interface Window {
    Cypress?: Boolean;
    __store__?: Store;
  }
}

function MyApp({ Component, pageProps }: Props) {
  const Layout = Component.Layout || (({ children }) => children);

  const store = useStore();
  // @ts-ignore
  const { persistor } = store;

  if (
    typeof window !== "undefined" &&
    process.env.NEXT_PUBLIC_CYPRESS_STORE === "true"
  ) {
    window.__store__ = store;
  }

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Provider store={store}>
        <PersistGate loading={<Spinner isSpinning />} persistor={persistor}>
          {/* <Script src="https://play.adtonos.com/attc-FoMvmb3sRm348teTk.min.js" /> */}
          <Head>
            <style>{dom.css()}</style>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest" />
            <link
              rel="mask-icon"
              href="/safari-pinned-tab.svg"
              color="#5bbad5"
            />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover"
            />
          </Head>
          <DefaultSeo
            titleTemplate="%s | The Rock Program"
            defaultTitle="The Rock Program"
            additionalLinkTags={[
              {
                rel: "icon",
                href: "/favicon.ico",
              },
              {
                rel: "apple-touch-icon",
                href: "/touch-icon-ipad.jpg",
                sizes: "76x76",
              },
              {
                rel: "manifest",
                href: "/manifest.json",
              },
            ]}
            openGraph={{
              type: "website",
              locale: "en_US",
              url: "https://www.therockprogram.com/",
              site_name: "The Rock Program",
            }}
            twitter={{
              handle: "@handle",
              site: "@site",
              cardType: "summary_large_image",
            }}
          />
          <MusicPlayerContainer>
            <Layout Component={Component}>
              <Component {...pageProps} />
            </Layout>
          </MusicPlayerContainer>
        </PersistGate>
      </Provider>
    </ChakraProvider>
  );
}

export default wrapper.withRedux(MyApp);
