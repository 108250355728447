import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";

import type { UserClient } from "db/schemas/user";
import type { AppState } from "store/store";
import storage from "util/persistStorage";

import auth from "./auth";
import forgotPassword from "./forgotPassword";
import login from "./login";
import register from "./register";
import registerArtist from "./registerArtist";
import resendEmailVerification from "./resendEmailVerification";
import resetPassword from "./resetPassword";
import updateUser from "./updateUser";
import verifyEmail from "./verifyEmail";

export interface AuthState {
  authorizing: boolean;
  token?: string;
  user?: UserClient;
  artistId?: string;
}

export const initialState: AuthState = {
  authorizing: false,
};

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    editUser: (state, action) => {
      state.user = action.payload;
    },
    loggedOut: (state) => {
      delete state.token;
      delete state.user;
      delete state.artistId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(auth.pending, (state) => {
      state.authorizing = true;
    });
    builder.addCase(auth.fulfilled, (state, action) => {
      state.authorizing = false;
      if (action.payload.ok) {
        state.token = action.payload.val.token;
        state.user = action.payload.val.user;
        state.artistId = action.payload.val.artistId;
      } else {
        delete state.token;
        delete state.user;
        delete state.artistId;
      }
    });
    builder.addCase(login.pending, (state) => {
      state.authorizing = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.authorizing = false;
      if (action.payload.ok) {
        state.token = action.payload.val.token;
        state.user = action.payload.val.user;
        state.artistId = action.payload.val.artistId;
      } else {
        delete state.token;
        delete state.user;
        delete state.artistId;
      }
    });
    builder.addCase(register.pending, (state) => {
      state.authorizing = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.authorizing = false;
      if (action.payload.ok) {
        state.token = action.payload.val.token;
        state.user = action.payload.val.user;
        state.artistId = action.payload.val.artistId;
      } else {
        delete state.token;
        delete state.user;
        delete state.artistId;
      }
    });
    builder.addCase(registerArtist.pending, (state) => {
      state.authorizing = true;
    });
    builder.addCase(registerArtist.fulfilled, (state, action) => {
      state.authorizing = false;
      if (action.payload.ok) {
        state.token = action.payload.val.token;
        state.user = action.payload.val.user;
        state.artistId = action.payload.val.artistId;
      } else {
        delete state.token;
        delete state.user;
        delete state.artistId;
      }
    });
  },
});

export const authActions = {
  ...authSlice.actions,
  auth,
  forgotPassword,
  login,
  register,
  registerArtist,
  resendEmailVerification,
  resetPassword,
  updateUser,
  verifyEmail,
};

export const selectAuthorizing = (state: AppState) => state.auth.authorizing;

export const selectToken = (state: AppState) => state.auth.token;

export const selectUser = (state: AppState) => state.auth.user;

export const selectArtistId = (state: AppState) => state.auth.artistId;

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["token"],
};

const persistedReducer = persistReducer(authPersistConfig, authSlice.reducer);

export default persistedReducer;
