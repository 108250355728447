import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";

import makeApiCall from "api/makeApiCall";

interface ResetPasswordParams {
  password: string;
  token: string;
}

const ResetPasswordResult = z.object({
  message: z.string(),
});

const resetPassword = createAsyncThunk(
  "resetPassword",
  async (params: ResetPasswordParams) => {
    return makeApiCall("/api/auth/resetPassword", params, ResetPasswordResult);
  }
);

export default resetPassword;
