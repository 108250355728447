import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";

import makeApiCall from "api/makeApiCall";
import { UserClientSchema } from "db/schemas/user";

interface LoginParams {
  email: string;
  password: string;
}

const LoginResult = z.object({
  token: z.string(),
  artistId: z.string().optional(),
  user: UserClientSchema,
});

const login = createAsyncThunk("login", async (params: LoginParams) => {
  const userResult = await makeApiCall("/api/auth/login", params, LoginResult);

  if (userResult.err) {
    return userResult;
  }

  return {
    ...userResult,
    val: { ...userResult.val, artistId: userResult.val.artistId },
  };
});

export default login;
