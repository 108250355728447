import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";

import makeApiCall from "api/makeApiCall";

interface VerifyEmailParams {
  token: string;
}

const VerifyEmailResult = z.object({
  message: z.string(),
});

const verifyEmail = createAsyncThunk(
  "verifyEmail",
  async (params: VerifyEmailParams) => {
    return makeApiCall("/api/auth/verifyEmail", params, VerifyEmailResult);
  }
);

export default verifyEmail;
