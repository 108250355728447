// Fix for redux-persist failed to create sync storage. falling back to noop storage.
// https://github.com/vercel/next.js/discussions/15687#discussioncomment-45319
import createWebStorage from "redux-persist/lib/storage/createWebStorage";
import type { Storage } from "redux-persist/lib/types";

const noopStorage: Storage = {
  getItem(_key) {
    return Promise.resolve(null);
  },
  setItem(_key, _value) {
    return Promise.resolve();
  },
  removeItem(_key) {
    return Promise.resolve();
  },
};

const storage =
  typeof window !== "undefined" ? createWebStorage("local") : noopStorage;

export default storage;
