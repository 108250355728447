import { z } from "zod";

import { date, isoString } from "util/zodExtensions";

export const ArtistDbSchema = z.object({
  id: z.string().uuid(),
  artist: z.string().min(1),
  artworkPath: z.string().optional().nullable(),
  bio: z.string().optional().nullable(),
  legalName: z.string().min(1),
  phone: z.string().min(1),
  postalCode: z.string().min(1),
  location: z.string(),
  createdAt: date.optional(),
  updatedAt: date.optional(),
});

// Client Schema uses ISO date strings instead of Date objects.
export const ArtistClientSchema = ArtistDbSchema.extend({
  createdAt: isoString.optional(),
  updatedAt: isoString.optional(),
});

export type ArtistDb = z.output<typeof ArtistDbSchema>;

export type ArtistClient = z.output<typeof ArtistClientSchema>;
