import type { PropsWithChildren } from "react";

import type { UserSong } from "db/schemas/userSong";
import type { PlayerState, ShuffleMode } from "features/player/types";
import type { PlayedSongRequestData } from "services/api";

export enum NextSongReason {
  Ended = "ended",
  Error = "error",
  Play = "play",
  Skip = "skip",
}

export interface MusicPlayerProps extends PropsWithChildren<{}> {
  authToken?: string;
  currentSong?: UserSong;
  currentSongUpdated: (song: UserSong) => void;
  nextSong: (reason: NextSongReason) => void;
  playerState: PlayerState;
  playlist: Array<UserSong>;
  playToggled: () => void;
  shuffleMode: ShuffleMode;
  shuffleModeToggled: () => void;
  songAddedToLibrary: (songId: string) => void;
  songDisliked: (songId: string) => void;
  songDownloaded: (songId: string) => void;
  songLiked: (songId: string) => void;
  songPlayed: (stats: PlayedSongRequestData) => void;
  songShared: (songId: string) => void;
  stopPlayback: () => void;
  volume: number;
  volumeUpdated: (volume: number) => void;
}
