import type { RootLogger } from "loglevel";
import logger from "loglevel";

import getRequestId from "./getRequestId";
interface Logger extends RootLogger {
  stringify: Function;
}

const customizedLogger: Partial<Logger> = logger;

const originalFactory = logger.methodFactory;
logger.methodFactory = (methodName, logLevel, loggerName) => {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);

  return function (...args) {
    const timestamp = new Date().toISOString();
    const level = methodName;

    let prefix = `${timestamp} ${level} -`;
    const requestId = getRequestId();
    if (requestId) {
      prefix += ` ${requestId} -`;
    }
    if (loggerName) {
      prefix += ` ${loggerName.toString()} -`;
    }

    const newArgs = [prefix, ...args];
    // @ts-ignore
    rawMethod.apply(this, newArgs);
  };
};
// Be sure to call setLevel method in order to apply plugin

logger.setDefaultLevel("debug");

customizedLogger.stringify = require("circular-json").stringify;

export default customizedLogger as Logger;
