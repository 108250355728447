import { Global } from "@emotion/react";

// This component provides custom fonts to the Chakra provider
const Fonts = () => {
  return (
    <Global
      styles={`
        /* latin */
        @font-face {
          font-family: 'BelfastGrotesk';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: local(''), url('/fonts/BelfastGrotesk-Black.ttf') format('truetype');
        }
        @font-face {
          font-family: 'BelfastGrotesk';
          font-style: normal;
          font-weight: 100 600;
          font-display: swap;
          src: local(''), url('/fonts/BelfastGrotesk-Medium.ttf') format('truetype');
        }
      `}
    />
  );
};

export default Fonts;
