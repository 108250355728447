import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";

import { NextSongReason } from "components/Player/MusicPlayer/types";
import type { UserSong } from "db/schemas/userSong";
import type { PlayerState, ShuffleMode } from "features/player/types";
import storage from "util/persistStorage";
import { SortDirection } from "util/sort";

export interface SongsState {
  currentSong?: UserSong;
  libraryBalance: number;
  playlist: Array<UserSong>;
  playerState: PlayerState;
  search: string;
  shuffleMode: ShuffleMode;
  shufflePlaylist: Array<UserSong>;
  sortBy: keyof UserSong;
  sortDirection: SortDirection;
  volume: number;
}

export const initialState: SongsState = {
  libraryBalance: 50,
  playlist: [],
  playerState: "paused",
  search: "",
  shuffleMode: "off",
  shufflePlaylist: [],
  sortBy: "title",
  sortDirection: SortDirection.Asc,
  volume: 0.8,
};

function nextSong(state: SongsState, reason: NextSongReason) {
  const { currentSong, playlist, shuffleMode, shufflePlaylist } = state;
  if (shuffleMode === "on") {
    // Shuffle mode, play a random song that is not the current song or a disliked song.
    const availableSongs = shufflePlaylist.filter(
      (o) => o.id !== currentSong?.id && !o.disliked
    );

    // Stop if there are no more songs to play.
    if (availableSongs.length === 0) {
      state.playerState = "paused";
      state.shufflePlaylist = playlist.slice();
      return;
    }

    const randomIndex = Math.floor(Math.random() * availableSongs.length);
    const nextSong = availableSongs[randomIndex];
    state.shufflePlaylist.splice(randomIndex, 1);
    state.currentSong = nextSong;
    state.playerState = "playing";
    return;
  } else {
    // Play the next non-disliked song, wrapping around if necessary.
    const index = playlist.findIndex((song) => song.id === currentSong?.id);
    const wrappedSongs =
      index === -1
        ? playlist
        : playlist.slice(index + 1).concat(playlist.slice(0, index));
    const availableSongs = wrappedSongs.filter((o) => !o.disliked);

    // Stop if there are no more songs to play.
    if (availableSongs.length === 0) {
      state.playerState = "paused";
      state.shufflePlaylist = playlist.slice();
      return;
    }

    const nextSong = availableSongs[0];
    state.currentSong = nextSong;
    state.playerState = "playing";
    return;
  }
}

export const songsSlice = createSlice({
  name: "songs",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    currentSongUpdated: (state, action: PayloadAction<UserSong>) => {
      state.currentSong = action.payload;
      state.playerState = "playing";
    },
    libraryBalanceUpdated: (state, action: PayloadAction<number>) => {
      state.libraryBalance = Math.max(Math.min(action.payload, 100), 0);
    },
    nextSong: (state, action: PayloadAction<NextSongReason>) =>
      nextSong(state, action.payload),
    playlistUpdated: (
      state,
      action: PayloadAction<{
        songs: Array<UserSong>;
        songId?: string;
      }>
    ) => {
      // Update songs and start playing if a song chosen.
      const { songs, songId } = action.payload;
      state.playlist = songs;
      state.shufflePlaylist = songs.slice();
      if (songId) {
        state.currentSong = songs.find((song) => song.id === songId);
        state.playerState = "playing";
      }
    },
    playToggled: (state) => {
      if (state.playerState === "paused") {
        if (state.currentSong) {
          state.playerState = "playing";
        } else {
          nextSong(state, NextSongReason.Play);
        }
      } else {
        state.playerState = "paused";
      }
    },
    searchUpdated: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    shuffleModeToggled: (state) => {
      state.shuffleMode = state.shuffleMode === "on" ? "off" : "on";
    },
    songUpdated: (state, action: PayloadAction<UserSong>) => {
      // Ensure currentSong kept in sync after like/dislike/etc.
      if (state.currentSong?.id === action.payload.id) {
        state.currentSong = action.payload;
      }
    },
    sortByUpdated: (state, action: PayloadAction<SongsState["sortBy"]>) => {
      const sortBy = action.payload;
      if (state.sortBy !== sortBy) {
        state.sortDirection = SortDirection.Asc;
        state.sortBy = action.payload;
      } else {
        state.sortDirection =
          state.sortDirection === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc;
      }
    },
    sortUpdated: (
      state,
      action: PayloadAction<Pick<SongsState, "sortBy" | "sortDirection">>
    ) => {
      const { sortBy, sortDirection } = action.payload;
      state.sortDirection = sortDirection;
      state.sortBy = sortBy;
    },
    stopPlayback: (state) => {
      state.playerState = "paused";
      delete state.currentSong;
    },
    volumeUpdated: (state, action: PayloadAction<number>) => {
      state.volume = action.payload;
    },
  },
});

export const songsActions = songsSlice.actions;

const songsPersistConfig = {
  key: "songs",
  storage,
  whitelist: [
    "libraryBalance",
    "search",
    "shuffleMode",
    "sortBy",
    "sortDirection",
    "volume",
  ],
};

const persistedReducer = persistReducer(songsPersistConfig, songsSlice.reducer);

export default persistedReducer;
