import type { PlayedSongRequestData } from "services/api";

type SkippedRanges = PlayedSongRequestData["skippedRanges"];

export default class PlayedSong {
  secondsListened = 0;
  accumulateFrom = 0;
  duration = 0;
  position = 0;
  readonly startedAt = new Date();
  readonly skippedRanges: SkippedRanges = [];

  constructor(readonly songId: string) {}

  updatePosition(position: number, duration: number) {
    this.position = position;
    this.duration = duration;
  }

  updateSkippedRanges(range: SkippedRanges[0]) {
    this.secondsListened += this.position - this.accumulateFrom;
    this.accumulateFrom = range.to;
    this.skippedRanges.push(range);
  }

  async songFinished(songPlayed: (stats: PlayedSongRequestData) => void) {
    this.secondsListened += this.position - this.accumulateFrom;

    const { songId, duration, secondsListened, startedAt, skippedRanges } =
      this;

    if (secondsListened > 0 && duration > 0) {
      const finishedAt = new Date().toISOString();
      const data: PlayedSongRequestData = {
        songId,
        duration,
        secondsListened,
        startedAt: startedAt.toISOString(),
        finishedAt,
        skippedRanges,
      };
      songPlayed(data);
    }
  }
}
