import type { PropsWithChildren } from "react";
import { bindActionCreators } from "redux";

import Spinner from "components/Spinner";
import { selectToken } from "features/auth/authSlice";
import { songsActions } from "features/songs/songsSlice";
import type { PlayedSongRequestData } from "services/api";
import {
  useDislikeSongMutation,
  useLibrarySongMutation,
  useLikeSongMutation,
  usePlayedSongMutation,
} from "services/api";
import { useAppDispatch, useAppSelector } from "store/hooks";

import MusicPlayer from "./Player";
import type { MusicPlayerProps } from "./types";

export default function MusicPlayerContainer({
  children,
}: PropsWithChildren<{}>) {
  const authToken = useAppSelector(selectToken);
  const songsState = useAppSelector((state) => state.songs);
  const { currentSong, playlist, playerState, shuffleMode, volume } =
    songsState;

  const dispatch = useAppDispatch();
  const {
    currentSongUpdated,
    nextSong,
    playToggled,
    shuffleModeToggled,
    stopPlayback,
    volumeUpdated,
  } = songsActions;
  const actions = bindActionCreators(
    {
      currentSongUpdated,
      nextSong,
      playToggled,
      shuffleModeToggled,
      stopPlayback,
      volumeUpdated,
    },
    dispatch
  );

  const [
    songAddedToLibrary, // This is the mutation trigger
    { isLoading: isLibrarying }, // This is the destructured mutation result
  ] = useLibrarySongMutation();
  const [
    songLiked, // This is the mutation trigger
    { isLoading: isLiking }, // This is the destructured mutation result
  ] = useLikeSongMutation();
  const [
    songDisliked, // This is the mutation trigger
    { isLoading: isDisliking }, // This is the destructured mutation result
  ] = useDislikeSongMutation();
  const [playedSong] = usePlayedSongMutation();
  const songPlayed = (data: PlayedSongRequestData) => {
    playedSong(data); // we don't await this, so make it void
  };
  const songDownloaded = (songId: string) => {};
  const songShared = (songId: string) => {};

  const showSpinner = isLibrarying || isDisliking || isLiking;

  const props: MusicPlayerProps = {
    authToken,
    children,
    currentSong,
    currentSongUpdated: actions.currentSongUpdated,
    nextSong: actions.nextSong,
    playerState,
    playlist,
    playToggled: actions.playToggled,
    songAddedToLibrary,
    songDownloaded,
    songDisliked,
    songLiked,
    songPlayed,
    songShared,
    shuffleModeToggled: actions.shuffleModeToggled,
    shuffleMode,
    stopPlayback: actions.stopPlayback,
    volume,
    volumeUpdated: actions.volumeUpdated,
  };

  return (
    <>
      <MusicPlayer {...props} />
      <Spinner isSpinning={showSpinner} />
    </>
  );
}
