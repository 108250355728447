import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";

import makeApiCall from "api/makeApiCall";

interface ForgotPasswordParams {
  email: string;
}

const ForgotPasswordResult = z.object({
  message: z.string(),
});

const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (params: ForgotPasswordParams) => {
    return makeApiCall(
      "/api/auth/forgotPassword",
      params,
      ForgotPasswordResult
    );
  }
);

export default forgotPassword;
