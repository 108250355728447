import _sortBy from "lodash/sortBy";

export enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

export default function sort<T>(
  values: Array<T>,
  sortBy: keyof T,
  sortDirection: SortDirection
) {
  const sorted = _sortBy(values, sortBy);
  if (sortDirection === SortDirection.Desc) {
    sorted.reverse();
  }
  return sorted;
}
