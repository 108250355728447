import { z } from "zod";

import { date, isoString } from "util/zodExtensions";

// Define schema and type outside of the model, as it may use server side apis.
export const UserDbSchema = z.object({
  id: z.string(),
  name: z.string().optional().nullable(),
  email: z.string().email(),
  image: z.string().optional().nullable(),
  imageUrl: z.string().optional().nullable(),
  postalCode: z.string().optional().nullable(),

  lastAccessAt: date.optional().nullable(),
  lastAccessIp: z.string().optional().nullable(),

  emailVerificationToken: z.string().optional().nullable(),
  emailVerificationExpiresAt: date.optional().nullable(),
  emailVerifiedAt: date.optional().nullable(),
  unverifiedEmail: z.string().optional().nullable(),

  password: z.string().optional().nullable(),
  passwordResetToken: z.string().optional().nullable(),
  passwordResetExpiresAt: date.optional().nullable(),

  roles: z.array(
    z.literal("user").or(z.literal("admin").or(z.literal("artist")))
  ),

  createdAt: date.optional(),
  updatedAt: date.optional(),
});

// Client Schema strips out sensitive data and uses ISO date strings instead of Date objects.
export const UserClientSchema = UserDbSchema.omit({
  emailVerificationToken: true,
  emailVerificationExpiresAt: true,
  emailVerifiedAt: true,
  unverifiedEmail: true,
  lastAccessAt: true,
  lastAccessIp: true,
  password: true,
  passwordResetToken: true,
  passwordResetExpiresAt: true,
})
  .strip()
  .extend({
    createdAt: isoString.optional(),
    updatedAt: isoString.optional(),
  });

export type UserDb = z.output<typeof UserDbSchema>;

export type UserClient = z.output<typeof UserClientSchema>;
