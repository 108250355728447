import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";

import makeApiCall from "api/makeApiCall";

interface ResendEmailVerificationParams {
  email: string;
}

const ResendEmailVerificationResult = z.object({
  message: z.string(),
});

const resendEmailVerification = createAsyncThunk(
  "resendEmailVerification",
  async (params: ResendEmailVerificationParams) => {
    return makeApiCall(
      "/api/auth/resendEmailVerification",
      params,
      ResendEmailVerificationResult
    );
  }
);

export default resendEmailVerification;
