import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";

import makeApiCall from "api/makeApiCall";
import { UserClientSchema } from "db/schemas/user";

interface RegisterParams {
  name: string;
  postalCode: string;
  email: string;
  password: string;
}

const RegisterResult = z.object({
  token: z.string(),
  artistId: z.string().optional(),
  user: UserClientSchema,
});

const register = createAsyncThunk(
  "register",
  async (params: RegisterParams) => {
    const userResult = await makeApiCall(
      "/api/auth/register",
      params,
      RegisterResult
    );

    if (userResult.err) {
      return userResult;
    }
    return {
      ...userResult,
      val: { ...userResult.val, artistId: userResult.val.artistId },
    };
  }
);

export default register;
