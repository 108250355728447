import React from "react";

import type { UserSong } from "db/schemas/userSong";
import type { PlayerState } from "features/player/types";
import { imageType } from "util/imageFormat";
import rootLogger from "util/logger";

const logger = rootLogger.getLogger("MediaSession");

const defaultSkipOffset = 10; /* Time to skip in seconds by default */

// enable/disable media buttons and update metadata in the MediaSession, if supported.
function addHandler(
  action: MediaSessionAction,
  handler: MediaSessionActionHandler
) {
  if ("mediaSession" in navigator) {
    try {
      navigator.mediaSession.setActionHandler(action, (...args) => {
        logger.info(action, ...args);
        handler(...args);
      });
    } catch (error) {
      logger.warn(`The media session action "${action}" is not supported yet.`);
    }
  }
}

function removeHandler(action: MediaSessionAction) {
  if ("mediaSession" in navigator) {
    try {
      navigator.mediaSession.setActionHandler(action, null);
    } catch (error) {
      logger.warn(`The media session action "${action}" is not supported yet.`);
    }
  }
}

interface MediaSessionManagerEvents {
  onNext: () => void;
  onPlay: () => void;
  onSeekBackward: (offset: number) => void;
  onSeekForward: (offset: number) => void;
  songSeeked: (position: number) => void;
}

export default class MediaSessionManager {
  constructor(events: MediaSessionManagerEvents) {
    addHandler("nexttrack", events.onNext);
    addHandler("pause", events.onPlay);
    addHandler("play", events.onPlay);
    // addHandler("seekbackward", (details) => {
    //   const offset = details.seekOffset || defaultSkipOffset;
    //   props.onSeekBackward(offset);
    // });
    // addHandler("seekforward", (details) => {
    //   const offset = details.seekOffset || defaultSkipOffset;
    //   props.onSeekForward(offset);
    // });
    addHandler("seekto", (details) => {
      events.songSeeked(details.seekTime || 0);
    });
  }

  destroy() {
    removeHandler("nexttrack");
    removeHandler("pause");
    removeHandler("play");
    removeHandler("seekbackward");
    removeHandler("seekforward");
    removeHandler("seekto");
  }

  updateMetadata(
    metadata: Pick<UserSong, "artist" | "artworkUrl" | "title"> | undefined
  ) {
    try {
      if ("mediaSession" in navigator && metadata) {
        const { artist, artworkUrl, title } = metadata;
        const src = artworkUrl || "/music.png";
        const type = imageType(src);

        logger.debug(`Updating mediaSession for ${title} by ${artist}`);
        navigator.mediaSession.metadata = new MediaMetadata({
          title,
          artist,
          artwork: [
            "96x96",
            "128x128",
            "192x192",
            "256x256",
            "384x384",
            "512x512",
          ].map((size) => ({
            src,
            sizes: size,
            type,
          })),
        });
      }
    } catch (error) {
      logger.warn(error);
    }
  }

  updatePlayerState(playerState: PlayerState) {
    try {
      if ("mediaSession" in navigator) {
        switch (playerState) {
          case "paused":
            navigator.mediaSession.playbackState = "paused";
            break;
          case "playing":
            navigator.mediaSession.playbackState = "playing";
            break;
          default:
            // To satisfy lint
            break;
        }
      }
    } catch (error) {
      logger.warn(error);
    }
  }

  updatePosition(position: number, duration: number) {
    try {
      if (
        "mediaSession" in navigator &&
        "setPositionState" in navigator.mediaSession
      ) {
        if (duration > 0) {
          navigator.mediaSession.setPositionState({
            position: position,
            duration: duration,
          });
        } else {
          logger.debug(`Updating mediaSession position to undefined`);
          navigator.mediaSession.setPositionState(undefined);
        }
      }
    } catch (error) {
      logger.warn(error);
    }
  }
}
