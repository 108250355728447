// import { createStandaloneToast } from "@chakra-ui/react";
import {
  configureStore,
  // isRejectedWithValue,
  // MiddlewareAPI,
  // Middleware,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { createWrapper } from "next-redux-wrapper";
import { combineReducers } from "redux";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist";

import artistsReducer from "features/artistsSlice";
import authReducer from "features/auth/authSlice";
import songsReducer from "features/songs/songsSlice";
import usersReducer from "features/usersSlice";
import { adminApi } from "services/adminApi";
import { api } from "services/api";
// import rootLogger from "util/logger";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  artists: artistsReducer,
  auth: authReducer,
  songs: songsReducer,
  users: usersReducer,
});

// const rtkQueryLogger = rootLogger.getLogger("rtkQuery");

// export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (
//   next
// ) => (action) => {
//   // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
//   if (isRejectedWithValue(action)) {
//     rtkQueryLogger.info("error", action.payload);
//     const description =
//       typeof action.payload.data.error === "string"
//         ? action.payload.data.error
//         : `Status: ${action.payload.status}`;

//     const toast = createStandaloneToast();
//     toast({
//       description,
//       duration: null,
//       isClosable: true,
//       position: "top",
//       status: "error",
//       title: "Error",
//     });
//   }

//   return next(action);
// };

export default function createStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(api.middleware, adminApi.middleware), // , rtkQueryErrorLogger),
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
  });

  // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
  // see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
  setupListeners(store.dispatch);

  const persistor = persistStore(store);
  type PersistentStore = typeof store & { persistor: typeof persistor };
  const persistentStore = store as PersistentStore;
  persistentStore.persistor = persistor;
  return persistentStore;
}

// Infer the `AppState` and `AppDispatch` types from the store itself
export type AppState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type Store = ReturnType<typeof createStore>;
export type AppDispatch = Store["dispatch"];

export const wrapper = createWrapper<Store>(createStore, { debug: true });
