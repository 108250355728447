import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";

import makeApiCall from "api/makeApiCall";
import { ArtistClientSchema } from "db/schemas/artist";
import { UserClientSchema } from "db/schemas/user";

interface RegisterArtistParams {
  legalName: string;
  phone: string;
  postalCode: string;
  artist: string;
  email: string;
  password: string;
}

const RegisterArtistResult = z.object({
  token: z.string(),
  artist: ArtistClientSchema,
  user: UserClientSchema,
});

const registerArtist = createAsyncThunk(
  "registerArtist",
  async (params: RegisterArtistParams) => {
    const userResult = await makeApiCall(
      "/api/auth/registerArtist",
      { ...params, name: params.legalName },
      RegisterArtistResult
    );

    if (userResult.err) {
      return userResult;
    }
    return {
      ...userResult,
      val: { ...userResult.val, artistId: userResult.val.artist.id },
    };
  }
);

export default registerArtist;
