import { Flex, Spinner as CSpinner } from "@chakra-ui/react";
import React from "react";
import { useSpinDelay } from "spin-delay";

interface SpinnerProps {
  delay?: number;
  isSpinning: boolean;
  minDuration?: number;
}

export default function Spinner({
  delay = 500,
  isSpinning,
  minDuration = 200,
}: SpinnerProps) {
  const showSpinner = useSpinDelay(isSpinning, { delay, minDuration });

  if (!showSpinner) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      bg="white"
      h="100vh"
      justifyContent="center"
      opacity={0.5}
      position="fixed"
      left={0}
      top={0}
      w="100vw"
    >
      <CSpinner />
    </Flex>
  );
}
