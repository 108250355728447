import isValid from "date-fns/isValid";
import parseISO from "date-fns/parseISO";
import { z } from "zod";

export const isoString = z.union([
  z.string().refine((value) => isValid(parseISO(value))),
  z.date().transform((value) => value.toISOString()),
]);

export const date = z.date().or(isoString.transform(parseISO));
