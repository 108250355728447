import noop from "lodash/noop";
import { createContext } from "react";

import type { UserSong } from "db/schemas/userSong";
import type { PlayerState, ShuffleMode } from "features/player/types";
import type { PlayedSongRequestData } from "services/api";

export interface Context {
  currentSong?: UserSong;
  currentSongUpdated: (song: UserSong) => void;
  duration: number;
  muted: boolean;
  muteToggled: () => void;
  playerState: PlayerState;
  playlist: Array<UserSong>;
  // playSong: (id: string) => void;
  playToggled: () => void;
  position: number;
  progressUpdated: (position: number, duration: number) => void;
  shuffleMode: ShuffleMode;
  shuffleModeToggled: () => void;
  songAddedToLibrary: (songId: string) => void;
  songDownloaded: (songId: string) => void;
  songDisliked: (songId: string) => void;
  songLiked: (songId: string) => void;
  songPlayed: (stats: PlayedSongRequestData) => void;
  songShared: (songId: string) => void;
  songSeeked: (position: number) => void;
  songSkipped: () => void;
  volume: number;
  volumeUpdated: (volume: number) => void;
}

const MusicPlayerContext = createContext<Context>({
  currentSong: undefined,
  currentSongUpdated: noop,
  duration: 0,
  muted: false,
  muteToggled: noop,
  playerState: "paused",
  playlist: [],
  // playSong: noop,
  playToggled: noop,
  position: 0,
  progressUpdated: noop,
  shuffleMode: "off",
  shuffleModeToggled: noop,
  songAddedToLibrary: noop,
  songDownloaded: noop,
  songDisliked: noop,
  songLiked: noop,
  songPlayed: noop,
  songSeeked: noop,
  songShared: noop,
  songSkipped: noop,
  volume: 0,
  volumeUpdated: noop,
});

export default MusicPlayerContext;
