import last from "lodash/last";

const imageExtensions: Record<string, string> = {
  "image/jpeg": "jpg",
  "image/png": "png",
};

const imageTypes: Record<string, string> = {
  jpg: "image/jpeg",
  png: "image/png",
};

export function imageType(path: string) {
  const ext = last(path.split(".")) || "";
  return imageTypes[ext];
}

export function imageExtension(type: keyof typeof imageExtensions) {
  return imageExtensions[type];
}
