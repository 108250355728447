import { extendTheme } from "@chakra-ui/react";

import { colors } from "./theme";

const theme = extendTheme({
  colors,
  fonts: {
    heading: "BelfastGrotesk",
    body: "BelfastGrotesk",
  },
  components: {
    Heading: {
      baseStyle: {
        color: "gray.800",
        fontSize: "4xl",
      },
    },
    Input: {
      parts: ["field"],
      baseStyle: {
        field: {
          color: "black",
          _placeholder: { color: "black" },
        },
      },
    },
    Checkbox: {
      borderRadius: "20px",
      baseStyle: {
        control: {
          borderRadius: "full",
          borderColor: "neutrals.four",
        },
      },
    },
    Menu: {
      parts: ["button"],
      baseStyle: {
        button: {
          _active: { background: "transparent" },
          _expanded: { background: "transparent" },
          _focus: { boxShadow: "outline" },
          _hover: { background: "transparent" },
        },
      },
    },
    Slider: {
      parts: ["container", "track", "thumb", "filledTrack"],
      baseStyle: {
        track: {
          background: "brand.500",
        },
        thumb: {
          background: "brand.500",
        },
        filledTrack: { background: "brand.500" },
      },
    },
    Spinner: {
      baseStyle: {
        color: "brand.500",
      },
      defaultProps: {
        size: "xl",
        thickness: "4px",
      },
    },
    Table: {
      parts: ["table", "td", "th", "tr"],
      defaultProps: { colorScheme: "brand" },
      baseStyle: {
        table: {
          background: "transparent",
        },
        thead: {
          th: {
            color: "black",
            borderBottomColor: "brand.500",
          },
        },
        tbody: {
          td: {
            borderBottomColor: "brand.500",
          },
          tr: {
            background: "transparent",
            _hover: { background: "inherited" },
          },
        },
      },
      variants: {
        noHover: {
          tbody: {
            tr: {
              _hover: { background: "inherit" },
            },
          },
        },
      },
    },
    Button: {
      defaultProps: { colorScheme: "brand" },
      baseStyle: {
        borderRadius: "20px",
        _hover: {
          _disabled: {
            backgroundColor: "neutrals.two",
            transform: "none",
          },
          transition: "all 0.1s ease-in-out",
          transform: "scale(0.98)",
        },
        _disabled: {
          // _hover: {},
          backgroundColor: "neutrals.two",
          color: "neutrals.three",
          opacity: 1,
        },
      },
      variants: {
        secondary: {
          backgroundColor: "none",
          border: "2px solid var(--chakra-colors-brand-500)",
          color: "brand.500",
          _disabled: {
            borderWidth: 0,
          },
        },
      },
    },
    Text: { baseStyle: { color: "gray.800" } },
  },
  styles: {
    global: {
      "*, *:before, *:after": {
        borderStyle: "solid",
        borderColor: "brand.500",
      },
      a: {
        color: "brand.500",
        textDecoration: "underline",
        _hover: { opacity: "0.8" },
      },
      body: {
        bgGradient:
          "linear(to-b, background.start, background.mid, background.finish)",
        height: "100vh",
        width: "100vw",
      },
    },
  },
});

export default theme;
