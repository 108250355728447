import Script from "next/script";
import { createRef, Component } from "react";
import type { VideoJsPlayer } from "video.js";
import videojs from "video.js";
import "videojs-contrib-ads";
import "videojs-ima";

import type { UserSong } from "db/schemas/userSong";
import EventManager from "util/EventManager";
import rootLogger from "util/logger";

import CircuitBreaker from "./CircuitBreaker";
import type { Context } from "./Context";
import MusicPlayerContext from "./Context";
import MediaSessionManager from "./MediaSessionManager";
import PlayedSong from "./PlayedSong";
import type { MusicPlayerProps } from "./types";
import { NextSongReason } from "./types";

const logger = rootLogger.getLogger("MusicPlayer");

interface State {
  duration: number;
  muted: boolean;
  position: number;
}

export default class MusicPlayer extends Component<MusicPlayerProps, State> {
  unlockedAudio = false;
  currentSong?: UserSong;
  eventManager = new EventManager();
  isPlaying = false;
  mediaSessionManager: MediaSessionManager;
  playbackStats?: PlayedSong;
  isFirstPlay = true;

  player?: VideoJsPlayer;
  state: State = {
    duration: 0,
    muted: false,
    position: 0,
  };
  circuitBreaker = createRef<CircuitBreaker>();

  private progressUpdated = (position: number, duration: number) => {
    this.setState({ position, duration });
  };

  private muteToggled = () => {
    const nextMuted = !this.state.muted;
    if (nextMuted) {
      this.setVolume(0);
    } else {
      this.setVolume(this.props.volume);
    }
    this.setState({ muted: nextMuted });
  };

  private step = () => {
    if (this.player && this.isPlaying) {
      const duration = this.player.duration();
      const position = this.player.currentTime();

      // only update the position when it changes
      if (position != this.state.position) {
        this.progressUpdated(position, duration);
        this.playbackStats?.updatePosition(position, duration);
        this.mediaSessionManager.updatePosition(position, duration);
      }

      setTimeout(this.step, 1000);
    }
  };

  private playInternal = async () => {
    if (this.player != null) {
      if (this.isPlaying) {
        logger.warn("player.play() being called when already playing");
      }
      this.player.play();
      logger.debug("player.play()");
    }
  };

  private pauseInternal = async () => {
    if (this.player != null) {
      if (this.player.paused()) {
        logger.warn("player.pause() being called when already paused");
      }
      this.player.pause();
      logger.debug("player.pause()");
    }
  };

  private changeSong = async (song: UserSong) => {
    const { authToken, currentSongUpdated, volume } = this.props;

    if (this.isPlaying) {
      this.pauseInternal();
    }

    this.isPlaying = false;
    if (!this.player) {
      logger.warn("No player instance available");
      return;
    }

    // MUST update first else we may end up in a componentDidUpdate loop.
    this.currentSong = song;
    this.playbackStats = new PlayedSong(song.id);
    currentSongUpdated(song);

    this.player.src({
      src: `/api/playSong?id=${song.id}&token=${authToken}`,
      type: "audio/mpeg",
    });

    this.player.volume(volume);

    await this.player.load();
    logger.debug(`player.load(), song = ${song.title} by ${song.artist}`);

    await this.playInternal().catch((error) => {
      logger.debug("changeSong play error =", error);
    });

    // fixes bug #227, first play gets stuck
    if (this.isFirstPlay) {
      logger.debug("firstPlay is called twice");
      this.playInternal()?.catch((error) => {
        logger.debug("firstPlay changeSong play error =", error);
      });
      this.isFirstPlay = false;
    }
  };

  play = (index = 0) => {
    const { songPlayed, playlist } = this.props;

    this.playbackStats?.songFinished(songPlayed);

    const song = playlist[index];
    if (song) {
      this.changeSong(song);
    } else {
      logger.error(`No song at index ${index}`);
    }
  };

  // playSong = (id: string) => {
  //   const { playlist } = this.props;
  //   const index = playlist.findIndex((o) => o.id === id);
  //   if (index >= 0) {
  //     this.play(index);
  //   } else {
  //     logger.error(`No song with id ${id}`);
  //   }
  // };

  songSeeked = (position: number) => {
    if (this.player) {
      if (this.isPlaying) {
        const currentPosition = this.player.currentTime();
        this.playbackStats?.updateSkippedRanges({
          from: currentPosition,
          to: position,
        });
        this.player.currentTime(position);
      } else {
        logger.debug(
          `while paused at ${this.player.currentTime()}, seeked to time ${position}`
        );
        if (this.player.currentTime() === position) {
          logger.warn("setting position when already at position");
        }
      }
    }
  };

  seekBackward = (offset: number) => {
    if (this.player && this.isPlaying) {
      const currentPosition = this.player.currentTime();
      const position = Math.max(currentPosition - offset, 0);
      this.songSeeked(position);
    }
  };

  seekForward = (offset: number) => {
    if (this.player && this.isPlaying) {
      const duration = this.player.duration();
      const currentPosition = this.player.currentTime();
      const position = Math.min(currentPosition + offset, duration);
      this.songSeeked(position);
    }
  };

  playToggled = () => {
    if (this.props.playerState === "playing") {
      this.pauseInternal();
      this.isPlaying = false;
    } else {
      this.player?.play()?.catch((error) => {
        logger.debug("playToggled error when calling play =", error);
      });
    }
    this.props.playToggled();
  };

  setVolume = (volume: number) => {
    if (this.player) {
      this.player.volume(volume);
    }
  };

  unlockAudio = async () => {
    const needUnlockAudio = false;
    // let's re-enable this when its needed
    // it's not working but would like to keep the code
    // around in the main branch for a bit so its
    // easy to put back and fix quickly if/when we need to.
    if (needUnlockAudio) {
      logger.debug("unlockAudio start");
      this.eventManager.removeAll(document.body);

      if (this.unlockedAudio) {
        logger.info("Audio already unlocked");
        return;
      }

      if (!this.player) {
        logger.warn("No player instance available in unlockAudio");
        return;
      }

      // Silence, stolen from Howler.
      this.player.src({
        src: "silence-1s.mp3", // use audio file resource that i created in logic. data:audio/wav from howler was blowing up safari iphone browser causing bug #235
        //src: "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA",
        type: "audio/wav",
      });

      // On mobile devices, you must call initializeAdDisplayContainer as the result
      // of a user action (e.g. button click). If you do not make this call, the SDK
      // will make it for you, but not as the result of a user action. For more info
      // see our examples, all of which are set up to work on mobile devices.
      // @ts-ignore - ima is not in the type definitions
      // this.player.ima?.initializeAdDisplayContainer();

    await this.player.load();
    await this.player.play()?.catch((error) => {
      logger.error("Playback error caught in unlockAudio =", error);
    });

      await this.player.load();
      logger.debug(`player loaded file =`, this.player.src());

      await this.playInternal();
      this.unlockedAudio = true;
      logger.debug("unlockAudio done");
    }
  };

  constructor(props: Readonly<MusicPlayerProps>) {
    super(props);

    this.eventManager.on(document.body, "click", this.unlockAudio, true);
    this.eventManager.on(document.body, "touchstart", this.unlockAudio, true);

    this.mediaSessionManager = new MediaSessionManager({
      onNext: () => props.nextSong(NextSongReason.Skip),
      onPlay: this.playToggled,
      onSeekBackward: this.seekBackward,
      onSeekForward: this.seekForward,
      songSeeked: this.songSeeked,
    });
  }

  onImaLoaded = () => {
    // @ts-ignore - ima plugin has no types
    // this.player?.ima({
    //   adTagUrl: "https://x3.instreamatic.com/v5/vast/777",
    //   //   adTagUrl: `https://play.adtonos.com/xml/KnfNCzW9aqfxc6zBj/daast.xml?adType=preroll&contentType=audio&targeting=on&cb=${cb}`,
    //   autoPlayAdBreaks: true,
    //   // Call player.ima.playAdBreak() in your ad break ready listener when you're ready to play the ads.
    // });
    this.player?.on("ads-manager", (response) => {
      logger.debug("ads-manager", response);
      // var adsManager = response.adsManager;
      // Your code in response to the `ads-manager` event.
    });
    // @ts-ignore - ima plugin has no types
    // this.player?.ima.setAdBreakReadyListener(() => {
    //   logger.debug("Ad break ready");
    //   // this.player.ima.playAdBreak();
    // });
  };

  async componentDidMount() {
    const cb = Math.round(Math.random() * 1_000_000_000_000);
    this.player = videojs("player", { controls: false });

    this.player.on("ended", () => {
      logger.debug(`ended ${this.currentSong?.title}`);
      if (this.props.playerState !== "paused") {
        // only call nextSong when we are not paused
        this.props.nextSong(NextSongReason.Ended);
      }
    });

    this.player.on("error", () => {
      logger.debug(
        `loaderror ${this.currentSong?.title}`,
        this.player?.error()
      );
      if (this.circuitBreaker.current?.failure(this.player?.error() || null)) {
        this.playToggled();
        this.props.stopPlayback();
      } else {
        this.props.nextSong(NextSongReason.Error);
      }
    });

    this.player.on("playing", () => {
      if (this.currentSong === undefined) {
        logger.debug(`started playing ${this.player?.src()}`);
      } else {
        logger.debug(
          `started playing ${this.currentSong?.title} by ${this.currentSong?.artist}`
        );
      }

      this.isPlaying = true;
      this.mediaSessionManager.updateMetadata(this.currentSong);
      // this.sound?.fade(0, 1, 300);
      setTimeout(this.step, 1000);
    });

    this.player.on("seeked", () => {
      logger.debug(
        `seeked ${this.currentSong?.title} to ${this.player?.currentTime()}`
      );
      setTimeout(this.step, 1000);
    });
  }

  componentDidUpdate(prevProps: Readonly<MusicPlayerProps>) {
    if (this.currentSong?.id !== this.props.currentSong?.id) {
      // Handle currentSong changing, e.g. from a double-click rather than
      // just playing the next song in the playlist naturally.
      const index = this.props.playlist.findIndex(
        (o) => o.id === this.props.currentSong?.id
      );

      logger.debug("componentDidUpdate: index =", index);
      if (index >= 0) {
        this.play(index);
      }
    } else if (
      prevProps.playerState &&
      prevProps.playerState !== this.props.playerState
    ) {
      if (!this.player) {
        logger.warn("No player instance available");
        return;
      }

      // Handle play/pause
      if (this.props.playerState === "paused") {
        if (this.player && this.isPlaying) {
          logger.debug("componentDidUpdate");
          this.pauseInternal()?.catch((error) => {
            logger.debug(
              "componentDidUpdate error when calling pause =",
              error
            );
          });
        }
      } else {
        if (this.player && !this.isPlaying)
          this.playInternal()?.catch((error) => {
            logger.debug("componentDidUpdate error when calling pause", error);
          });
      }
      this.mediaSessionManager.updatePlayerState(this.props.playerState);
    }

    if (prevProps.volume !== this.props.volume) {
      this.setVolume(this.props.volume);
    }
  }

  componentWillUnmount() {
    this.eventManager.removeAll();
    this.mediaSessionManager.destroy();
    if (!this.player?.isDisposed()) {
      this.player?.dispose();
    }
  }

  render() {
    const {
      // playSong,
      progressUpdated,
      songSeeked,
      muteToggled,
      playToggled,
    } = this;
    const {
      children,
      currentSong,
      currentSongUpdated,
      nextSong,
      playerState,
      playlist,
      shuffleMode,
      shuffleModeToggled,
      songAddedToLibrary,
      songDownloaded,
      songDisliked,
      songLiked,
      songPlayed,
      songShared,
      volume,
      volumeUpdated,
    } = this.props;
    const { duration, muted, position } = this.state;
    const value: Context = {
      currentSong,
      currentSongUpdated,
      duration,
      muted,
      muteToggled,
      playerState,
      playlist,
      // playSong,
      playToggled,
      position,
      progressUpdated,
      shuffleMode,
      shuffleModeToggled,
      songAddedToLibrary,
      songDownloaded,
      songDisliked,
      songLiked,
      songPlayed,
      songSeeked,
      songShared,
      songSkipped: () => nextSong(NextSongReason.Skip),
      volume,
      volumeUpdated,
    };
    return (
      <MusicPlayerContext.Provider value={value}>
        <Script
          src="https://imasdk.googleapis.com/js/sdkloader/ima3.js"
          onLoad={this.onImaLoaded}
        />
        <div>
          <div data-vjs-player style={{ display: "none" }}>
            <audio id="player" className="video-js" />
          </div>
        </div>
        {children}
        <CircuitBreaker ref={this.circuitBreaker} />
      </MusicPlayerContext.Provider>
    );
  }
}
