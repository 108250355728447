import MultiMap from "./MultiMap";

interface EventListener {
  (evt: Event): void;
}

// Plain EventTarget to avoid the need of creating fake elements, etc.
// Adapted from https://developer.mozilla.org/en-US/docs/Web/API/EventTarget
class SimpleEventTarget {
  listeners: Record<string, Array<Function>> = {};

  addEventListener = (type: string, callback: Function) => {
    if (!(type in this.listeners)) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  };

  removeEventListener = (type: string, callback: Function) => {
    if (!(type in this.listeners)) {
      return;
    }
    const stack = this.listeners[type];
    for (let i = 0, l = stack.length; i < l; i += 1) {
      if (stack[i] === callback) {
        stack.splice(i, 1);
        return;
      }
    }
  };

  dispatchEvent = (event: Event) => {
    if (!(event.type in this.listeners)) {
      return true;
    }
    const stack = this.listeners[event.type];
    // event.target = this // NOTE: read-only
    for (let i = 0, l = stack.length; i < l; i += 1) {
      stack[i].call(this, event);
    }
    return !event.defaultPrevented;
  };
}

type Target = EventTarget | SimpleEventTarget;

// Creates a new binding and attaches the event `listener` to the event `target`
class Binding {
  constructor(
    public target: Target,
    public type: string,
    public listener: EventListener,
    public options?: AddEventListenerOptions | boolean
  ) {
    this.target.addEventListener(type, listener, options);
  }

  // Detaches the `event` `listener` from the `event` `target`. Does nothing if already detached.
  off() {
    this.target.removeEventListener(this.type, this.listener, this.options);
  }
}

export default class EventManager {
  _bindingMap = new MultiMap<Binding>();

  // Attaches an event `listener` to an event `target`.
  on(
    target: Target,
    type: string,
    listener: EventListener,
    options?: AddEventListenerOptions | boolean
  ) {
    const binding = new Binding(target, type, listener, options);
    this._bindingMap.set(type, binding);
  }

  // Attaches an event `listener` to an event `target`.
  // The `listener` will be removed after the first time the event is fired.
  once(target: Target, type: string, listener: EventListener) {
    // Wrap the listener so we can stop listening after the first event.
    const wrapper = (event: Event) => {
      this.off(target, type, wrapper);
      listener(event);
    };
    this.on(target, type, wrapper);
  }

  // Detaches an event `listener` from an event `target`.
  // If `listener` is not specified, all listeners are removed.
  off(target: Target, type: string, listener?: Function) {
    const bindings = this._bindingMap.get(type) || [];
    for (const binding of bindings) {
      if (target === binding.target) {
        if (!listener || listener === binding.listener) {
          binding.off();
          this._bindingMap.delete(type, binding);
        }
      }
    }
  }

  // Detaches all event listeners from all targets.
  removeAll(target?: Target) {
    const bindings = this._bindingMap.entries().map((entry) => entry[1]);
    for (const binding of bindings) {
      if (!target || target === binding.target) {
        binding.off();
        this._bindingMap.delete(binding.type, binding);
      }
    }
  }
}
