import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { ArtistClient } from "db/schemas/artist";
import { SortDirection } from "util/sort";

export interface ArtistsState {
  search: string;
  sortBy: keyof ArtistClient;
  sortDirection: SortDirection;
}

export const initialState: ArtistsState = {
  search: "",
  sortBy: "artist",
  sortDirection: SortDirection.Asc,
};

export const artistsSlice = createSlice({
  name: "artists",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    searchUpdated: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    sortByUpdated: (state, action: PayloadAction<ArtistsState["sortBy"]>) => {
      const sortBy = action.payload;
      if (state.sortBy !== sortBy) {
        state.sortDirection = SortDirection.Asc;
        state.sortBy = action.payload;
      } else {
        state.sortDirection =
          state.sortDirection === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc;
      }
    },
  },
});

export const artistsActions = artistsSlice.actions;

export default artistsSlice.reducer;
