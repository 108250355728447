import { bindActionCreators, createAsyncThunk } from "@reduxjs/toolkit";

import makeApiCall from "api/makeApiCall";
import { UserClientSchema } from "db/schemas/user";

import { authActions } from "./authSlice";

interface Params {
  id: string;
  updateUserData: {
    name?: string;
    email?: string;
    postalCode?: string;
  };
  authToken: string;
}

const updateUser = createAsyncThunk(
  "updateUser",
  async (params: Params, { dispatch }) => {
    const actions = bindActionCreators(authActions, dispatch);
    const result = await makeApiCall(
      `/api/auth/updateUser?token=${params.authToken}`,
      {
        id: params.id,
        updateData: params.updateUserData,
      },
      UserClientSchema
    );

    if (result.err) {
      return result;
    }
    actions.editUser(result.val);
    return result;
  }
);

export default updateUser;
