// Look into https://github.com/thien-do/typed.tw
// Particularly the webpack loader.
const colors = {
  brand: {
    500: "#8878FB",
    "500_9": "#8878FB90",
    "500_6": "#8878FB60",
    "500_3": "#8878FB30",
  },
  background: {
    start: "#F9F9F9",
    mid: "#F9F9F9",
    finish: "#F9F9F9",
  },
  border: "var(--chakra-colors-purple-400)",
  primaries: {
    bright1: "#0CDBDB",
    bright2: "#FF1A5E",
    dark1: "#082747",
    dark2: "#000000",
  },
  neutrals: {
    four: "#8F8F8F",
    three: "#A8A8A8",
    two: "#D9D9D9",
    one: "#F9F9F9",
    one_6: "#F9F9F960",
    border: "#ECECEC",
    black: "#000000",
    black_3: "#00000025",
    black2: "#232123",
  },
  status: {
    error: "var(--chakra-colors-red-400)",
    errors: {
      400: "#F56565",
      "400_6": "#F5656560",
      "400_3": "#F5656530",
    },
    success: "var(--chakra-colors-green-400)",
    warning: "var(--chakra-colors-blue-400)",
  },
};

const spacing = {
  xxxl: 24,
  xxl: 20,
  xl: 16,
  lg: 14,
  md: 12,
  sm: 9,
  xs: 6,
  xxs: 3,
};

const fontSizes = {
  headingLg: "30px",
  headingLg2: "33px",
  headingLg3: "36px",
  headingMd: "28px",
  headingSm: "23px",
  headingXs: "19px",
  labelSm: "13px",
  bodyMd: "16px",
  bodySm: "13px",
  btn: "16px",
};

const widthPercents = {
  max: "100%",
  lg: "88%",
  md: "80%",
  sm: "50%",
};
const widths = {
  max: "768px",
  min: "320px",
  borderMin: "15px",
  borderMed: "30px",
};

const paddings = {
  xs: "6px",
  sm: "15px",
  md: "30px",
};

module.exports = {
  colors,
  fontSizes,
  spacing,
  widthPercents,
  widths,
  paddings,
};
