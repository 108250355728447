import { Err, Ok } from "ts-results";
import type { ZodError, ZodType } from "zod";

import { DomainError } from "util/errors";
import processZodError from "validation/processZodError";

export class ValidationError extends DomainError {
  public constructor(zodError: ZodError) {
    const fieldErrors = processZodError(zodError);
    super(JSON.stringify(fieldErrors));
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

export default function validate(schema: ZodType<any>, data: any) {
  const result = schema.safeParse(data);
  if (result.success) {
    return Ok(result.data);
  }

  return Err(new ValidationError(result.error));
}
