import axios from "axios";
import type { Result } from "ts-results";
import { Err } from "ts-results";
import type { z } from "zod";

import type { ValidationError } from "validation";
import validate from "validation";

function processAxiosError(error: any): Error {
  if (axios.isAxiosError(error)) {
    if (error.response?.data?.error) {
      return new Error(error.response.data.error);
    }
    return new Error(error.message);
  } else if (error instanceof Error) {
    return error;
  } else {
    return new Error(error);
  }
}

export default async function makeApiCall<P, R extends z.ZodType<any>>(
  path: string,
  params: P,
  responseSchema: R
): Promise<Result<z.infer<R>, ValidationError>> {
  try {
    const response = await axios.post<R>(path, params);
    return validate(responseSchema, response.data);
  } catch (error) {
    return Err(processAxiosError(error));
  }
}
