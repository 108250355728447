import get from "lodash/get";
import set from "lodash/set";
import type { z } from "zod";

interface FieldErrors {
  [key: string]: string;
}

export default function processZodError(zodError: z.ZodError) {
  const fieldErrors: FieldErrors = {};

  const processError = (e: z.ZodError) => {
    for (const error of e.errors) {
      if (error.code === "invalid_union") {
        error.unionErrors.map(processError);
      } else if (error.path.length === 0) {
        const message = get(fieldErrors, "error");
        set(
          fieldErrors,
          "error",
          message ? `${message}\n${error.message}}` : error.message
        );
      } else {
        const path = error.path.map((x) => x.toString());
        const currentErrorAtPath = get(fieldErrors, path);
        set(
          fieldErrors,
          path,
          currentErrorAtPath // if error already exists, append as new line
            ? `${error.message}\n${currentErrorAtPath}`
            : error.message
        );
      }
    }
  };
  processError(zodError);

  return fieldErrors;
}
