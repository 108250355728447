// A simple MultiMap
export default class MultiMap<V> {
  _map: Record<string, Array<V>> = {};

  // Creates the map given the optional initial values.
  constructor(iterable: Iterable<[string, V]> | undefined = undefined) {
    this.clear();
    if (iterable) {
      for (const entry of iterable) {
        this.set(entry[0], entry[1]);
      }
    }
  }

  // Gets the size of the list (number of keys).
  get size() {
    return Object.getOwnPropertyNames(this._map).length;
  }

  // Gets the values for `key` or undefined if `key` does not exist.
  get(key: string): Array<V> | undefined {
    const values = this._map[key];
    // slice() clones the array for safety.
    return values ? values.slice() : undefined;
  }

  // Gets all the entries as an array of [key, value]
  entries() {
    const entries: Array<[string, V]> = [];
    for (const key of Object.getOwnPropertyNames(this._map)) {
      const entry: Array<[string, V]> = this._map[key].map((value) => [
        key,
        value,
      ]);
      entries.push(...entry);
    }
    return entries;
  }

  // Adds the `value` to the `key`, adding `key1 if it does not exist.
  set(key: string, value: V) {
    if (Object.prototype.hasOwnProperty.call(this._map, key)) {
      this._map[key].push(value);
    } else {
      this._map[key] = [value];
    }
  }

  // Removes the `value` from the `key` if `key` exists.
  delete(key: string, value?: V) {
    const values = this._map[key];
    if (!values) {
      return false;
    }
    for (let i = 0; i < values.length; i += 1) {
      if (values[i] === value) {
        values.splice(i, 1);
        return true;
      }
    }
    return false;
  }

  // Clears the entire map.
  clear() {
    this._map = {};
  }
}
