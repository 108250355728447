import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { UserClient } from "db/schemas/user";
import { SortDirection } from "util/sort";

export interface UsersState {
  search: string;
  sortBy: keyof UserClient;
  sortDirection: SortDirection;
}

export const initialState: UsersState = {
  search: "",
  sortBy: "name",
  sortDirection: SortDirection.Asc,
};

export const usersSlice = createSlice({
  name: "users",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    searchUpdated: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    sortByUpdated: (state, action: PayloadAction<UsersState["sortBy"]>) => {
      const sortBy = action.payload;
      if (state.sortBy !== sortBy) {
        state.sortDirection = SortDirection.Asc;
        state.sortBy = action.payload;
      } else {
        state.sortDirection =
          state.sortDirection === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc;
      }
    },
  },
});

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;
