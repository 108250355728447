import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
} from "@chakra-ui/react";
import { Component } from "react";

import rootLogger from "util/logger";

const logger = rootLogger.getLogger("CircuitBreaker");

const failureThreshold = 3;
const timeoutSeconds = 5;

interface State {
  isOpen: boolean;
  failureCount: number;
  errorMessage?: string;
  secondsUntilReset: number;
}

export default class CircuitBreaker extends Component<{}, State> {
  state = {
    isOpen: false,
    failureCount: 0,
    errorMessage: undefined,
    secondsUntilReset: 0,
  };

  timeoutId?: ReturnType<typeof setInterval>;

  componentWillUnmount() {
    if (this.timeoutId) {
      clearInterval(this.timeoutId);
    }
  }

  failure = (error: MediaError | null) => {
    const failureCount = this.state.failureCount + 1;
    if (failureCount < failureThreshold) {
      this.setState({ failureCount });
      return false;
    }

    logger.warn("Circuit breaker tripped");
    this.setState({
      isOpen: true,
      failureCount,
      errorMessage: error?.message || "Unknown error",
      secondsUntilReset: timeoutSeconds,
    });
    this.timeoutId = setInterval(() => {
      if (this.state.secondsUntilReset > 1) {
        this.setState({
          secondsUntilReset: this.state.secondsUntilReset - 1,
        });
      } else {
        logger.warn("Circuit breaker reset");
        clearInterval(this.timeoutId!);
        this.setState({
          isOpen: false,
          failureCount: 0,
        });
      }
    }, 1000);
    return true;
  };

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    return (
      <Modal isOpen isCentered onClose={() => {}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Playback Error</ModalHeader>
          <ModalBody>
            <Text>{this.state.errorMessage}</Text>
            <Text>Closing in {this.state.secondsUntilReset}...</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
}
