import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";

import makeApiCall from "api/makeApiCall";
import { UserClientSchema } from "db/schemas/user";

interface AuthParams {
  token: string;
}

const AuthResult = z.object({
  token: z.string(),
  artistId: z.string().optional(),
  user: UserClientSchema,
});

const auth = createAsyncThunk("auth", async (params: AuthParams) => {
  const userResult = await makeApiCall("/api/auth/auth", params, AuthResult);

  if (userResult.err) {
    return userResult;
  }

  return {
    ...userResult,
    val: { ...userResult.val, artistId: userResult.val.artistId },
  };
});

export default auth;
